import React, { useState, useEffect } from 'react';
const API_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8080/';
const FILES_URL = 'https://vaiago.fra1.cdn.digitaloceanspaces.com';

const SelectedAccommodations = ({ cityId, selectedAccommodation }) => {
  const [accommodations, setAccommodations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredAccommodations, setFilteredAccommodations] = useState([]);

  useEffect(() => {
    // Function to fetch accommodations data from the backend API
    const fetchAccommodations = async () => {
      try {
        const response = await fetch(`${API_URL}api/selected-accommodations/${cityId}`);
        
        // Debugging response status and headers
        const contentType = response.headers.get('Content-Type');

        if (!response.ok) {
          throw new Error('Failed to fetch selected accommodations');
        }

        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          setAccommodations(data);
        } else {
          throw new Error('Response is not JSON');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error during fetch:', err); // Log the error message
      } finally {
        setLoading(false);
      }
    };

    fetchAccommodations();
  }, [cityId]);

  useEffect(() => {
    // Check if selectedAccommodation is an array and filter based on the single value
    if (selectedAccommodation && Array.isArray(selectedAccommodation) && selectedAccommodation.length > 0) {
      const accommodationType = selectedAccommodation[0]; // Get the single value from the array
      const filtered = accommodations.filter((accommodation) =>
        accommodation.type === accommodationType
      );
      setFilteredAccommodations(filtered);
    } else {
      // If selectedAccommodation is empty or null, show all accommodations
      setFilteredAccommodations(accommodations);
    }
  }, [accommodations, selectedAccommodation]);

  if (loading) {
    return <p>Loading accommodations...</p>;
  }

  if (error) {
    console.error('Error encountered:', error);
    return <p>{error}</p>;
  }


  return (
    <div>
        {filteredAccommodations.length === 0 ? (<></>) : 
        (
            <>
            <h2>Hébergements recommandés</h2>
                <div className="accommodations">
                    {filteredAccommodations.map((accommodation) => (
                    <a 
                        href={`${accommodation.link}${accommodation.partner === 'greengo' ? '#ae3506' : ''}`} 
                        target="_blank" 
                        key={accommodation.id}
                    >
                        <div className="accommodation">
                        <img src={accommodation.ogImage} alt={`Image of ${accommodation.ogTitle}`} />
                        <div>
                            <h3>{accommodation.ogTitle}</h3>
                            <p>{accommodation.ogDescription}</p>
                            {accommodation.partner === 'greengo' && (
                            <div className="partner">
                                <p>Voir sur</p>
                                <img src={`${FILES_URL}/img/app/greengo.avif`} alt="Greengo Logo" />
                            </div>
                            )}
                        </div>
                        </div>
                    </a>
                    ))}
                </div>
            </>
        )}
      
    </div>
  );
};

export default SelectedAccommodations;