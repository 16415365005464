import React, { useState } from 'react';
import { supabase } from './supabaseClient'; // Assurez-vous que le chemin d'accès est correct
import './App.css';
import frLocale from 'date-fns/locale/fr';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptNewsletter, setAcceptNewsletter] = useState(false); // État pour l'acceptation des CGU
  const newsletter = acceptNewsletter; 
  const [acceptCGU, setAcceptCGU] = useState(false); // État pour l'acceptation des CGU
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!acceptCGU) {
      setError("Vous devez accepter les Conditions Générales d'Utilisation pour vous inscrire.");
      return;
    }
    setLoading(true);
    setError(null);

    const { user, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          newsletter
        }
      }
    });

    if (error) {
      setError(error.message);
    } else {
      navigate('/'); // Redirection vers la page d'accueil
    }

    setLoading(false);
  };

  return (
    <div className="App">  
        <Helmet>
            <title>Vaiago - Inscription</title>
            <meta name="description" content="En quelques clics, trouvez la destination qui correspond à vos envies et à votre budget (transport, hébergement et nourriture inclus)" />
        </Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
        <link rel="canonical" href="https://www.vaiago.com" />
      
        <div>
            <h1>Créer un compte Vaiago</h1>
            <div className="container signin">
                <form onSubmit={handleSignUp} className="form">
                    <div className="input">
                        <label className="label" htmlFor="email">E-mail :</label>
                        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required/> 
                    </div>
                    <div className="input">
                        <label className="label" htmlFor="password">Mot de passe :</label>
                        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <div className="input checkbox-container"> 
                      <input type="checkbox" id="cgu" class="checkbox-custom" checked={acceptCGU} onChange={(e) => setAcceptCGU(e.target.checked)}/>
                      <label for="cgu">J'accepte les <a href="/conditions-generales-utilisation">Conditions Générales d'Utilisation</a></label>
                    </div>
                    <div className="input checkbox-container"> 
                      <input type="checkbox" id="newsletter" class="checkbox-custom" checked={acceptNewsletter} onChange={(e) => setAcceptNewsletter(e.target.checked)}/>
                      <label for="newsletter">Je souhaite recevoir les nouveautés de Vaiago par e-mail</label>
                    </div>
                    <button type="submit" disabled={loading}>
                    {loading ? 'Loading...' : 'Créer mon compte'}
                    </button>
                    {error && <p className="error">{error}</p>}
                    <p>Déjà inscrit(e) ? <a href="/connexion">Connectez-vous !</a></p>
  </form>
            </div>
        </div>
    </div>
  );
};

export default SignUp;
