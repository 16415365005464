import React, { useState } from 'react';
import { supabase } from './supabaseClient'; 
import './App.css';
import frLocale from 'date-fns/locale/fr';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setError(error.message);
    } else {
      navigate('/'); // Redirection
    }
    setLoading(false);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault(); // Ajouté pour éviter le rechargement de la page
    if (!resetEmail) {
      alert("Veuillez entrer votre adresse e-mail.");
      return;
    }

    const { data, error } = await supabase.auth.resetPasswordForEmail(resetEmail, {
      redirectTo: '/reinitialisation',
    })
    if (error) {
      setError(error.message);
    } else {
      setError("Un lien de réinitialisation de mot de passe a été envoyé à votre adresse e-mail.");
    }
  };


  return (
    <div className="App">  
        <Helmet>
            <title>Vaiago - Connexion</title>
            <meta name="description" content="En quelques clics, trouvez la destination qui correspond à vos envies et à votre budget (transport, hébergement et nourriture inclus)" />
        </Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
        <link rel="canonical" href="https://www.vaiago.com" />
      
      {isResettingPassword ? (
       <div>
          <h1>Réinitialiser le mot de passe</h1>
          <div className="container">
              <form onSubmit={handlePasswordReset} className="form">
              <div className="input">
                      <label className="label" htmlFor="email">E-mail :</label>
                      <input type="email" placeholder="Email" value={resetEmail} onChange={(e) => setResetEmail(e.target.value)} required/> 
                  </div>
            <button type="submit">Réinitialiser</button>
            <a href="#" onClick={() => setIsResettingPassword(false)}>Retour à la connexion</a>
            {error && <p className="error">{error}</p>}
            </form>
          </div>
        </div>) : 
        (
        <div>
          <h1>Connexion à Vaiago</h1>
          <div className="container">
              <form onSubmit={handleLogin} className="form">
                  <div className="input">
                      <label className="label" htmlFor="email">E-mail :</label>
                      <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required/> 
                  </div>
                  <div className="input">
                      <label className="label" htmlFor="password">Mot de passe :</label>
                      <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </div>
                  <button type="submit" disabled={loading}>
                  {loading ? 'Loading...' : 'Me connecter'}
                  </button>
                  <a href="#" onClick={() => setIsResettingPassword(true)}>Mot de passe oublié ?</a>

                  {error && <p className="error">{error}</p>}
                  <p>Pas encore inscrit(e) ? <a href="/inscription">Créez un compte gratuitement.</a></p>
              </form>
          </div>
        </div>
        )}
    </div>
  );
};

export default Login;
