import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';

const FILES_URL = 'https://vaiago.fra1.cdn.digitaloceanspaces.com'


const AddDestinationToTravelModal = ({ isOpen, onRequestClose, destinationId }) => {
    const [error, setError] = useState('');
    const [userTravels, setUserTravels] = useState([]);
    const [selectedTravel, setSelectedTravel] = useState(''); 
    const [userId, setUserId] = useState(''); 
    const navigate = useNavigate();
  
    useEffect(() => {
      if (!isOpen) return;
    
      const fetchUserTravels = async () => {
        setError('');
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          setError('Utilisateur non connecté.');
          return;
        }
    
        const { data: travels, error: fetchError } = await supabase
          .from('user_travels')
          .select('*')
          .eq('user_id', user.id);
    
        if (fetchError) {
          setError('Erreur lors de la récupération des voyages');
          console.error('Erreur lors de la récupération des voyages', fetchError);
        } else {
          setUserTravels(travels);
          // Ici, initialisez selectedTravel avec l'ID de la première option de voyage
          if (travels && travels.length > 0) {
            setSelectedTravel(travels[0].id.toString());
          }
        }
      };
    
      fetchUserTravels();
    }, [isOpen]);

    const handleChange = (e) => {
      setSelectedTravel(e.target.value);
    };
  

  
  
    // Fonction pour ajouter la destination au voyage sélectionné
    const addDestinationToTravel = async (e) => {
        e.preventDefault(); // Empêchez le formulaire de se soumettre de manière traditionnelle
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          setError('Utilisateur non connecté.');
          return;
        }
    
        if (!selectedTravel) {
          setError('Veuillez sélectionner un voyage.');
          return;
        }


        const { data: existingEntries, error: existingError } = await supabase
          .from('travel_destinations')
          .select('*')
          .eq('user_travel_id', selectedTravel)
          .eq('destination_id', destinationId);
      

        if (existingError) {
          setError('Erreur lors de la vérification de la destination.');
          console.error(existingError);
          return;
        }
      
        if (existingEntries.length > 0) {
          setError('Cette destination a déjà été ajoutée à ce voyage.');
          return;
        }
      
        // Ajouter la destination au voyage
        const { error } = await supabase
          .from('travel_destinations')
          .insert([
            { user_travel_id: selectedTravel, destination_id: destinationId, user_id: user.id }
          ]);
          if (error) {
            setError('Erreur lors de l\'ajout de la destination au voyage.');
            console.error(error);
          } else {
            onRequestClose(); // Fermez la modale
            navigate(`/voyages/${selectedTravel}`); // Redirigez l'utilisateur vers le voyage sélectionné
          }
      };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} >  
    
    { userTravels.length > 0 ? (
        <form onSubmit={addDestinationToTravel} className="form-modal">
         <h2>Ajoutez la destination à l'un de vos voyages.</h2> 
        <div className="close  hidden-desktop"><button className="close-btn" onClick={onRequestClose}><i className="fa-solid fa-xmark"></i></button></div>
          <div className="input">
              <label>Sélectionnez un voyage :</label>
              {userTravels.map((travel) => (
                  <div key={travel.id}>
                      <label class="custom-radio">
                        <input
                          type="radio"
                          id={travel.id}
                          name="travelGroup"
                          value={travel.id}
                          checked={Number(selectedTravel) === travel.id}
                          onChange={handleChange}
                        />
                        <span class="radio-btn"> {travel.name}</span>
                        </label>
                  </div>
              ))}
          </div>
          <button type="submit">Ajouter la destination</button>
          {error && <p className="error">{error}</p>}      
        </form>
     ) : (
         <div className="empty-state">
          <p>Oups, vous n'avez pas encore créé de voyage.</p> 
          <img src={`${FILES_URL}/img/app/empty.webp`} className="empty" />
          <a href="/voyages"><button>Ajouter un voyage</button></a>
         </div>
      )}
    </Modal>
  );
};

export default AddDestinationToTravelModal;
