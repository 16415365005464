import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import axios from 'axios';
import { format, addMonths } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CustomSelect from './CustomSelect';

const API_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080/';
const FILES_URL = 'https://vaiago.fra1.cdn.digitaloceanspaces.com';

const App = () => {
  const trackingId = 'G-ZJQ921GBMX';

  const [selectedCity, setSelectedCity] = useState('');
  const [budget, setBudget] = useState('');
  const [stayDuration, setStayDuration] = useState('1');
  const [travelTime, setTravelTime] = useState('');
  const [availableTravels, setAvailableTravels] = useState([]);
  const [cities, setCities] = useState([]);
  const selectedCityName = cities.find(city => city.id === selectedCity)?.name;
  const [availableCities, setAvailableCities] = useState([]);
  const defaultTransportValues = ['train', 'bus', 'car', 'ferry'];
  const defaultPeopleValue = ['1'];
  const defaultFoodValue = ['middle'];
  const defaultAccommodationValue = ['middle'];

  const [selectedTransport, setSelectedTransport] = useState(defaultTransportValues);
  const [selectedPeople, setSelectedPeople] = useState(defaultPeopleValue);
  const [selectedFood, setSelectedFood] = useState(defaultFoodValue);
  const [selectedAccommodation, setSelectedAccommodation] = useState(defaultAccommodationValue);

  const transportOptions = [
    { value: 'train', label: 'Train' },
    { value: 'bus', label: 'Bus' },
    { value: 'car', label: 'Voiture' },
    { value: 'ferry', label: 'Ferry' }
  ];

  const peopleOptions = [
    { value: '1', label: '1 personne' },
    { value: '2', label: '2 personnes' },
    { value: '3', label: '3 personnes' },
    { value: '4', label: '4 personnes' },
    { value: '5', label: '5 personnes' },
    { value: '6', label: '6 personnes' }
  ];

  const accomodationOptions = [
    { value: 'none', label: 'Ne pas inclure' },
    { value: 'low', label: 'Auberge de jeunesse' },
    { value: 'middle', label: 'Hébergement 2 à 3 étoiles' },
    { value: 'high', label: 'Hébergement haut de gamme' }
  ];

  const foodOptions = [
    { value: 'none', label: 'Ne pas inclure' },
    { value: 'low', label: 'Repas sur le pouce' },
    { value: 'middle', label: 'Restos milieu de gamme' },
    { value: 'high', label: 'Restos haut de gamme' }
  ];

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const toggleMoreFilters = () => {
    setShowMoreFilters(!showMoreFilters);
  };

  const handleFormClick = () => {
    setShowForm(true);
  };

  const [showForm, setShowForm] = useState(false);

  const videoRefDesktop = useRef(null);
  const [isPlayingDesktop, setIsPlayingDesktop] = useState(false);
  const [isHoveredDesktop, setIsHoveredDesktop] = useState(false);

  const videoRefMobile = useRef(null);
  const [isPlayingMobile, setIsPlayingMobile] = useState(false);
  const [isHoveredMobile, setIsHoveredMobile] = useState(false);

  const handlePlayPauseDesktop = () => {
    if (videoRefDesktop.current) {
      if (isPlayingDesktop) {
        videoRefDesktop.current.pause();
      } else {
        videoRefDesktop.current.play();
      }
      setIsPlayingDesktop(!isPlayingDesktop);
    }
  };

  const handleMouseEnterDesktop = () => {
    setIsHoveredDesktop(true);
  };

  const handleMouseLeaveDesktop = () => {
    setIsHoveredDesktop(false);
  };

  const handlePlayPauseMobile = () => {
    if (videoRefMobile.current) {
      if (isPlayingMobile) {
        videoRefMobile.current.pause();
      } else {
        videoRefMobile.current.play();
      }
      setIsPlayingMobile(!isPlayingMobile);
    }
  };

  const handleMouseEnterMobile = () => {
    setIsHoveredMobile(true);
  };

  const handleMouseLeaveMobile = () => {
    setIsHoveredMobile(false);
  };
  const [favorites, setFavorites] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [trackingId]);

  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);
    setIsLoading(true);

    try {
      const transportParams = {
        train: selectedTransport.includes('train'),
        bus: selectedTransport.includes('bus'),
        car: selectedTransport.includes('car'),
        ferry: selectedTransport.includes('ferry')
      };

      const availableTravels = await fetchAvailableTravels(
        selectedCity,
        selectedPeople,
        selectedAccommodation,
        selectedFood,
        budget,
        transportParams,
        stayDuration,
        travelTime
      );

      if (!selectedCity) {
        console.error('La ville d\'origine n\'a pas été trouvée');
      } else {
        const originCity = getCityById(selectedCity);
        const updatedAvailableTravels = await Promise.all(
          availableTravels.map(async (travel) => {
            const destinationCity = getCityById(travel.destination_id);
            const minTrainBudget = parseFloat(travel.budgets.lowTransportBudget);
            const maxTrainBudget = parseFloat(travel.budgets.highTransportBudget);
            return {
              ...travel,
              minTrainBudget,
              maxTrainBudget
            };
          })
        );
        setAvailableTravels(updatedAvailableTravels);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des voyages:', error.message);
    }
    setIsLoading(false);

    // Save form data to local storage
    const formData = {
      selectedCity,
      selectedCityName,
      selectedAccommodation,
      selectedFood,
      selectedPeople,
      budget,
      stayDuration,
      selectedTransport
    };

    localStorage.setItem('searchFormData', JSON.stringify(formData));
  };

  useEffect(() => {
    fetch(`${API_URL}api/origin_cities`)
      .then(response => response.json())
      .then(data => {
        const cityOptions = data.map(city => ({
          value: city.id,
          label: city.name
        }));
        setAvailableCities(cityOptions);
        
        const savedFormData = localStorage.getItem('searchFormData');
        if (savedFormData) {
          const formData = JSON.parse(savedFormData);
          if (formData.selectedCity && cityOptions.some(option => option.value === formData.selectedCity)) {
            setSelectedCity(formData.selectedCity);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}api/cities`)
      .then(response => response.json())
      .then(data => {
        setCities(data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des villes:', error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}api/favorites`)
      .then(response => response.json())
      .then(data => {
        setFavorites(data);
      })
      .catch(error => {
        console.error('Error fetching favorites:', error);
      });
  }, []);

  useEffect(() => {
    const savedFormData = localStorage.getItem('searchFormData');
    if (savedFormData) {
      const formData = JSON.parse(savedFormData);
      if (formData.selectedCity) setSelectedCity(formData.selectedCity);
      if (formData.budget) setBudget(formData.budget);
      if (formData.selectedPeople) setSelectedPeople(formData.selectedPeople);
      if (formData.stayDuration) setStayDuration(formData.stayDuration);
      if (formData.selectedTransport) setSelectedTransport(formData.selectedTransport);
    }
  }, []);

  const fetchAvailableTravels = async (
    selectedCity,
    selectedPeople,
    selectedAccommodation,
    selectedFood,
    budget,
    transportParams,
    stayDuration,
    travelTime,
  ) => {
    try {
      const response = await fetch(
        `${API_URL}api/available-travels?origin_id=${selectedCity}&people=${selectedPeople}&accommodation=${selectedAccommodation}&food=${selectedFood}&budget=${budget}&stay_duration=${stayDuration}&transport=${JSON.stringify(transportParams)}&travel_time=${travelTime}`
      );
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des voyages.');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erreur lors de la récupération des voyages:', error.message);
      return [];
    }
  };

  const handleCityChange = (selectedCityId) => {
    setSelectedCity(selectedCityId);
    updateFormDataInLocalStorage({ selectedCity: selectedCityId });
  };

  const handlePeopleChange = (selectedPeopleId) => {
    setSelectedPeople(selectedPeopleId);
    updateFormDataInLocalStorage({ selectedPeople: selectedPeopleId });
  };

  const handleFoodChange = (e) => {
    setSelectedFood(e.target.value);
  };

  const handleAccommodationChange = (e) => {
    setSelectedAccommodation(e.target.value);
  };

  const handleBudgetChange = (e) => {
    setBudget(e.target.value);
    updateFormDataInLocalStorage({ budget: e.target.value });
  };

  const handleTransportChange = (selectedValues) => {
    setSelectedTransport(selectedValues);
    updateFormDataInLocalStorage({ selectedTransport: selectedValues });
  };

  const handleTravelTimeChange = (e) => {
    setTravelTime(e.target.value);
  };

  const handleStayDurationChange = (e) => {
    setStayDuration(e.target.value);
    updateFormDataInLocalStorage({ stayDuration: e.target.value });
  };

  const updateFormDataInLocalStorage = (newData) => {
    const currentFormData = JSON.parse(localStorage.getItem('searchFormData')) || {};
    const updatedFormData = { ...currentFormData, ...newData };
    localStorage.setItem('searchFormData', JSON.stringify(updatedFormData));
  };

  const getCityNameById = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : '';
  };

  const getCityById = (cityId) => {
    return cities.find((city) => city.id === cityId);
  };

  return (
    <div className="App">
      <Helmet>
        <title>Vaiago - Trouvez votre prochaine destination</title>
        <meta name="description" content="En quelques clics, trouvez la destination qui correspond à vos envies et à votre budget (transport, hébergement et nourriture inclus)" />
      </Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet" />
      <link rel="canonical" href="https://www.vaiago.com" />

      <div className="hero hidden-mobile">
        <div className="hero-content">
          <h1>Trouvez votre prochain voyage</h1>
          <p>En quelques clics, trouvez la destination qui correspond à vos envies et à votre budget (transport, hébergement et nourriture inclus)</p>
        </div>
        <form onSubmit={handleSubmit} className="form">
          {!showForm && (
            <div className='small-form'>
              <div className="input">
                <label className="label" htmlFor="budget">Quel est votre budget total ?</label>
                <div className="input-container">
                  <input type="number" id="budget" value={budget} onChange={handleBudgetChange} onClick={handleFormClick} required />
                  <span className="unit">euros</span>
                </div>
              </div>
              <div className="input">
                <label className="label" htmlFor="city">D'où partez-vous ?</label>
                <CustomSelect
                  id="city"
                  options={availableCities}
                  selectedValues={selectedCity ? [selectedCity] : []}
                  onChange={(value) => { handleCityChange(value); handleFormClick(); }}
                  placeholder="Sélectionnez une ville"
                  required
                />
              </div>

              <div className="input">
                <label className="label" htmlFor="stayDuration">Pour combien de nuits ?</label>
                <div className="input-container">
                  <input type="number" id="stayDuration" placeholder="Nombre de nuits" value={stayDuration} onChange={handleStayDurationChange} onClick={handleFormClick} required />
                  <span className="unit">nuit(s)</span>
                </div>
              </div>
              <div className="input">
                <label className="label" htmlFor="people">Et combien de personnes ?</label>
                <div className="custom-select">
                <CustomSelect
                    id="people"
                    options={peopleOptions}
                    isMulti={false}
                    selectedValues={selectedPeople}
                    onChange={(value) => { handlePeopleChange(value); handleFormClick(); }}
                    />
                </div>
              </div>
              <div className="input">
                <label className="label" htmlFor="transport">Quels moyens de transport ?</label>
                <CustomSelect
                  options={transportOptions}
                  isMulti={true}
                  selectedValues={selectedTransport}
                  onChange={handleTransportChange}
                  onClick={handleFormClick} 
                  placeholder="Ne pas inclure"
                />
              </div>
            </div>
          )}
          {showForm && (
            <div className="showform">
              <div>
                <div className="input">
                  <label className="label" htmlFor="budget">Quel est votre budget total ?</label>
                  <div className="input-container">
                    <input type="number" id="budget" value={budget} onChange={handleBudgetChange} required />
                    <span className="unit">euros</span>
                  </div>
                </div>
                <div className="input">
                  <label className="label" htmlFor="city">D'où partez-vous ?</label>
                  <CustomSelect
                    id="city"
                    options={availableCities}
                    selectedValues={selectedCity ? [selectedCity] : []}
                    onChange={handleCityChange}
                    required
                    placeholder="Sélectionnez une ville"
                    />
                </div>

                <div className="input">
                  <label className="label" htmlFor="stayDuration">Pour combien de nuits ?</label>
                  <div className="input-container">
                    <input type="number" id="stayDuration" placeholder="Nombre de nuits" value={stayDuration} onChange={handleStayDurationChange} required />
                    <span className="unit">nuit(s)</span>
                  </div>
                </div>
                <div className="input">
                  <label className="label" htmlFor="people">Et combien de personnes ?</label>
                  <div className="custom-select">
                  <CustomSelect
                    id="people"
                    options={peopleOptions}
                    isMulti={false}
                    selectedValues={selectedPeople}
                    onChange={handlePeopleChange}
                  />
                  </div>
                </div>
                <div className="input">
                  <label className="label" htmlFor="transport">Par quels moyens de transport ?</label>
                  <CustomSelect
                    options={transportOptions}
                    isMulti={true}
                    selectedValues={selectedTransport}
                    onChange={handleTransportChange}
                    placeholder="Ne pas inclure"
                  />
                </div>
              </div>

              <div className="toggle"><button className="btn-toggle" onClick={toggleMoreFilters}>{showMoreFilters ? "Afficher moins de filtres" : "Afficher plus de filtres"}</button></div>
              {
                showMoreFilters && (
                  <div>
                    <div className="input">
                      <label className="label" htmlFor="accommodation_type">Type d'hébergement :</label>
                      <CustomSelect
                        options={accomodationOptions}
                        isMulti={false}
                        selectedValues={selectedAccommodation}
                        onChange={setSelectedAccommodation}
                        />
                    </div>
                    <div className="input">
                      <label className="label" htmlFor="food_type">Budget repas :</label>
                      <CustomSelect
                        options={foodOptions}
                        isMulti={false}
                        selectedValues={selectedFood}
                        onChange={setSelectedFood}
                      />
                    </div>
                    <div className="input">
                      <label className="label" htmlFor="travelTime">Durée de trajet maximale :</label>
                      <div className="input-container">
                        <input type="number" id="travelTime" placeholder="10" value={travelTime} onChange={handleTravelTimeChange} />
                        <span className="unit">heure(s)</span>
                      </div>
                    </div>
                  </div>
                )
              }
              <div>
              </div>

              <button type="submit" onClick={handleClick}>Je trouve ma destination</button>
              <p className="disclaimer">Vaiago vous invite à privilégier les destinations accessibles avec des moyens de transport bas carbone (train, bus) 🌍.</p>
            </div>
          )}
        </form>
      </div>

      <div className="hero hidden-desktop">
        <div className="hero-content">
          <h1>Trouvez votre prochain voyage</h1>
          <p>En quelques clics, trouvez la destination qui correspond à vos envies et à votre budget (transport, hébergement et nourriture inclus)</p>
        </div>
        <form onSubmit={handleSubmit} className="form">
          {!showForm && (
            <div>
              <div className="input">
                <label className="label" htmlFor="budget">Quel est votre budget total ?</label>
                <div className="input-container">
                  <input type="number" id="budget" value={budget} onChange={handleBudgetChange} onClick={handleFormClick} required />
                  <span className="unit">euros</span></div>
              </div>
            </div>
          )}
          {showForm && (
            <div className="showform">
              <div>
                <div className="input">
                  <label className="label" htmlFor="budget">Quel est votre budget total ?</label>
                  <div className="input-container">
                    <input type="number" id="budget" value={budget} onChange={handleBudgetChange} required />
                    <span className="unit">euros</span>
                  </div>
                </div>
                <div className="input">
                  <label className="label" htmlFor="city">D'où partez-vous ?</label>
                  <CustomSelect
                    id="city"
                    options={availableCities}
                    selectedValues={selectedCity ? [selectedCity] : []}
                    onChange={handleCityChange}
                    required
                    placeholder="Sélectionnez une ville"
                    />
                </div>

                <div className="input">
                  <label className="label" htmlFor="stayDuration">Pour combien de nuits ?</label>
                  <div className="input-container">
                    <input type="number" id="stayDuration" placeholder="Nombre de nuits" value={stayDuration} onChange={handleStayDurationChange} required />
                    <span className="unit">nuit(s)</span>
                  </div>
                </div>
                <div className="input">
                  <label className="label" htmlFor="people">Et combien de personnes ?</label>
                  <div className="custom-select">
                    <CustomSelect
                      options={peopleOptions}
                      isMulti={false}
                      selectedValues={selectedPeople}
                      onChange={setSelectedPeople}
                    />
                  </div>
                </div>
                <div className="input">
                  <label className="label" htmlFor="transport">Par quels moyens de transport ?</label>
                  <CustomSelect
                    options={transportOptions}
                    isMulti={true}
                    selectedValues={selectedTransport}
                    onChange={handleTransportChange}
                    placeholder="Ne pas inclure"
                  />
                </div>
              </div>

              <div className="toggle"><button className="btn-toggle" onClick={toggleMoreFilters}>{showMoreFilters ? "Afficher moins de filtres" : "Afficher plus de filtres"}</button></div>
              {
                showMoreFilters && (
                  <div>
                    <div className="input">
                      <label className="label" htmlFor="accommodation_type">Type d'hébergement :</label>
                      <CustomSelect
                        options={accomodationOptions}
                        isMulti={false}
                        selectedValues={selectedAccommodation}
                        onChange={setSelectedAccommodation}
                      />
                    </div>
                    <div className="input">
                      <label className="label" htmlFor="food_type">Budget repas :</label>
                      <CustomSelect
                        options={foodOptions}
                        isMulti={false}
                        selectedValues={selectedFood}
                        onChange={setSelectedFood}
                      />
                    </div>
                    <div className="input">
                      <label className="label" htmlFor="travelTime">Durée de trajet maximale :</label>
                      <div className="input-container">
                        <input type="number" id="travelTime" placeholder="10" value={travelTime} onChange={handleTravelTimeChange} />
                        <span className="unit">heure(s)</span>
                      </div>
                    </div>
                  </div>
                )
              }
              <div>
              </div>

              <button type="submit" onClick={handleClick}>Je trouve ma destination</button>
              <p className="disclaimer">Pour le moment, Vaiago privilégie les destinations accessibles avec des moyens de transport bas carbone (train, bus et parfois liaison bateau) 🌍.</p>
            </div>
          )}
        </form>
      </div>

      <div className="page">
        {isFormSubmitted ? (
          <div>
            {availableTravels.length > 0 ? (
              <div>
                <h2 ref={ref}> Vaiago vous suggère les destinations suivantes </h2>
                {isLoading ? (
                  <span className="loader"></span>
                ) : (
                  <div>
                    <div className="destinations">
                      {availableTravels
                        .slice()
                        .sort((a, b) => {

                          const priceComparison = a.budgets.totalLowTravelBudget - b.budgets.totalLowTravelBudget;

                          if (priceComparison === 0) {
                            const timeComparison = a.travel_time - b.travel_time;
                            return timeComparison;
                          }

                          return priceComparison;
                        })
                        .map((travel) => (
                          <Link to={`/destination/${travel.destination_slug}`} className="destination-link" key={travel.destination_id}>
                            <div className="card-destination">
                              <div className="picture">
                                <img src={`${FILES_URL}/img/destinations/${travel.destination_slug}-cover.webp`} alt={`Visitez ${travel.destination_name}`} />
                              </div>
                              <div className="content">
                                <h2>{getCityNameById(travel.destination_id)} ({travel.country_name})</h2>
                                <div>
                                  <ul>
                                  {travel.budgets.totalLowTravelBudget > 0 ? (
                                      <li><strong>Budget total estimé : {travel.budgets.totalLowTravelBudget}€ - {travel.budgets.totalHighTravelBudget}€</strong><br />{selectedPeople > 1 ? (<span>(soit {travel.budgets.lowTravelBudgetByPeople}€ - {travel.budgets.highTravelBudgetByPeople} € par personne)</span>) : ''}</li>
                                    ) : (
                                      <li><strong>Budget total estimé : {travel.budgets.totalLowTravelBudget}€</strong><br />{selectedPeople > 1 ? (<span>(soit {travel.budgets.lowTravelBudgetByPeople}€ par personne)</span>) : ''}</li>
                                    )}
                                  </ul>
                                  <ul>
                                    {travel.budgets.totalAccommodationBudget > 0 ? (
                                      <li>Hébergement : {travel.budgets.totalAccommodationBudget}€</li>
                                    ) : (
                                      <li>Sans hébergement</li>
                                    )}
                                    {travel.budgets.totalFoodBudget > 0 ? (
                                      <li>Budget repas : {travel.budgets.totalFoodBudget}€</li>
                                    ) : (
                                      <li>Sans budget repas</li>
                                    )}
                                    {travel.budgets.transportBudgets.length === 0 ? (
                                      <li>Transport non-inclus</li>
                                    ) :
                                    travel.budgets.transportBudgets.length === 1 ? (
                                      <li>
                                        {travel.budgets.transportBudgets[0].type} : {travel.budgets.transportBudgets[0].lowTransportBudget}€ - {travel.budgets.transportBudgets[0].highTransportBudget}€  (durée : {travel.time_readable})
                                      </li>
                                    ) : (
                                      <li>
                                         Transport (au choix) : 
                                        <ul className="sublist">
                                          {travel.budgets.transportBudgets
                                            .slice()
                                            .sort((a, b) => a.type.localeCompare(b.type))
                                            .map((budget, index) => (
                                              <li key={index}>{budget.type} : {budget.lowTransportBudget}€ - {budget.highTransportBudget}€ (durée : {budget.time_readable})</li>
                                            ))}
                                        </ul>  
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                    </div>
                    <div><p>Les budgets indiqués sont ceux généralement constatés sur la période sélectionnée. <br />Vérifiez les budgets avant de partir à l’aventure.</p></div>
                  </div>
                )}
              </div>
            ) : (
              <div className="empty-state">
                <h2 ref={ref}> Oups, la recherche n'a rien donné !</h2>
                <p>Il n'y a aucun voyage disponible pour vos critères. Essayez d'augmenter légèrement votre budget.</p>
                <img src={`${FILES_URL}/img/app/empty.webp`} className="empty" alt="No results" />
              </div>
            )}
          </div>
        ) :
          <div>
            <div className="hidden-mobile">
              <div className="demo hidden-mobile">
                <div
                  className="video-container"
                  onMouseEnter={handleMouseEnterDesktop}
                  onMouseLeave={handleMouseLeaveDesktop}
                >
                  <video
                    ref={videoRefDesktop}
                    width="600"
                    playsInline
                    loop
                    poster={`${FILES_URL}/img/app/video.webp`}
                    style={{ display: 'block' }}
                  >
                    <source src={`${FILES_URL}/video/vaiago_demo.mp4`} type="video/mp4" />
                    Votre navigateur ne supporte pas la balise vidéo.
                  </video>
                  {(isHoveredDesktop || !isPlayingDesktop) && (
                    <button className="play-button" onClick={handlePlayPauseDesktop}>
                      {isPlayingDesktop ? (<i className='fa fa-pause'></i>) : (<i className='fa fa-play'></i>)}
                    </button>
                  )}
                </div>
                <div class="demo-text">
                  <h2>Créez votre compte pour organiser vos voyages en quelques clics !</h2>
                  <p>Vaiago réinvente la manière dont vous organisez vos voyages : fini la galère pour trouver LA destination qui correspond à vos envies ET à votre budget. </p>
                  <br></br>
                  <a href="/inscription"><button>Je créé mon compte</button></a>
                </div>
              </div>
            </div>
            <div className="hidden-desktop">
              <div className="demo ">
                <div
                  className="video-container"
                  onMouseEnter={handleMouseEnterMobile}
                  onMouseLeave={handleMouseLeaveMobile}
                >
                  <video
                    ref={videoRefMobile}
                    width="600"
                    playsInline
                    loop
                    poster={`${FILES_URL}/img/app/video_mobile.webp`}
                    style={{ display: 'block' }}
                  >
                    <source src={`${FILES_URL}/video/vaiago_demo_mobile.mp4`} type="video/mp4" />
                    Votre navigateur ne supporte pas la balise vidéo.
                  </video>
                  {(isHoveredMobile || !isPlayingMobile) && (
                    <button className="play-button" onClick={handlePlayPauseMobile}>
                      {isPlayingMobile ? (<i className='fa fa-pause'></i>) : (<i className='fa fa-play'></i>)}
                    </button>
                  )}
                </div>
                <div class="demo-text">
                  <h2>Créez votre compte pour organiser vos voyages en quelques clics !</h2>
                  <p>Vaiago réinvente la manière dont vous organisez vos voyages : fini la galère pour trouver LA destination qui correspond à vos envies ET à votre budget. </p>
                  <br></br>
                  <a href="/inscription"><button>Je créé mon compte</button></a>
                </div>
              </div>
            </div>
            <div>
              <h2 ref={ref}>Découvrez nos destinations préférées</h2>
              <div className="favorites">
                {favorites && favorites.length > 0 ? favorites.map((destination) => (
                  <Link to={`/destination/${destination.slug}`} className="destination-link">
                    <div className="card-favorite">
                      <div className="picture">
                        <img src={`${FILES_URL}/img/destinations/${destination.slug}-cover.webp`} alt={`Visitez ${destination.name}`} />
                      </div>
                      <div className="card-content">
                        <h3>{destination.name}</h3>
                        <p>{destination.short_description}</p>
                      </div>
                    </div>
                  </Link>
                )) : <p></p>}
              </div>
              <br></br>
              <a href="/pays" alt="Voir toutes les destinations sélectionnées par Vaiago">Voir toutes les destinations</a>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default App;
