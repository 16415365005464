import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient'; // Assurez-vous que le chemin d'accès est correct
import './App.css';
import frLocale from 'date-fns/locale/fr';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


const AccountForm = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [acceptNewsletter, setAcceptNewsletter] = useState(false); // État pour l'acceptation des CGU
    const newsletter = acceptNewsletter; 
   
    const [confirmationMessage, setConfirmationMessage] = useState('');
  
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      city: '',
      email: '',
      password: '',
      birthDate: '',
      newsletter: false,
    });
  
    useEffect(() => {
        const fetchUserData = async () => {
            const { data: { user } } = await supabase.auth.getUser()
            if (user) {
            const { data, error: dataError } = await supabase
              .from('user_details')
              .select('*')
              .eq('user_id', user.id)
              .single();
      
            if (data) {
              setFormData({
                ...formData,
                firstName: data.first_name || '',
                lastName: data.last_name || '',
                city: data.city || '',
                email: user.email,
                birthDate: data.birth_date || '',
                newsletter: data.newsletter || false,
              });
            }
      
            if (dataError) {
              console.error('Erreur lors de la récupération des données utilisateur', dataError);
            }
          } 
        };
      
        fetchUserData();
      }, []);
      
  
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(null);
      setConfirmationMessage(''); 

      const { data: { user }, error: userError } = await supabase.auth.getUser();

      if (userError) {
        console.error('Erreur lors de la récupération de l’utilisateur connecté:', userError);
        setError("Erreur lors de la récupération de l'utilisateur.");
        setLoading(false);
        return;
      }
    
    
      if (!user) {
        setError("Aucun utilisateur connecté.");
        return;
      }

      const deleteUserAccount = async () => {
  const user = supabase.auth.user();

  if (user) {
    const { error } = await supabase.auth.api.deleteUser(user.id, process.env.SUPABASE_SERVICE_ROLE_KEY);
    
    if (error) {
      console.error('Erreur lors de la suppression du compte:', error);
    } else {
      // Vous pouvez rediriger l'utilisateur ou afficher un message ici
    }
  }
};
    
      setLoading(true);
    
      const { data, error } = await supabase.from('user_details').upsert({
        user_id: user.id, 
        first_name: formData.firstName,
        last_name: formData.lastName,
        city: formData.city,
        email: formData.email,
        birth_date: formData.birthDate,
        newsletter: formData.newsletter,
      }, {
        returning: "minimal", // Pour éviter de recevoir toutes les données en réponse
      });
    
      if (error) {
        console.error('Erreur lors de l\'envoi des données utilisateur:', error);
        setError('Erreur lors de l\'envoi des données.');
      } else {
        setConfirmationMessage('Vos informations ont été sauvegardées avec succès.');
        // Optionnellement, réinitialiser le formulaire ou effectuer d'autres actions ici
      }
    
      setLoading(false);
    };
    
  
    return (
    <div className="App">  
        <Helmet>
            <title>Vaiago - Inscription</title>
            <meta name="description" content="En quelques clics, trouvez la destination qui correspond à vos envies et à votre budget (transport, hébergement et nourriture inclus)" />
        </Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
        <link rel="canonical" href="https://www.vaiago.com" />
      
        <div>
            <h1>Mon compte Vaiago</h1>
            <div className="container signin">
                <form onSubmit={handleSubmit} className="form">
                  <div className="input-row">
                    <div className="input">
                        <label className="label" htmlFor="firstName">Prénom :</label>
                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="Prénom" /> 
                    </div>
                    <div className="input">
                        <label className="label" htmlFor="lastName">Nom :</label>
                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Nom" /> 
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input">
                        <label className="label" htmlFor="city">Ville :</label>
                        <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="Ville" /> 
                    </div>
                    
                    <div className="input">
                        <label className="label" htmlFor="birthDate"> Date de naissance :</label>
                        <input type="date" name="birthDate" value={formData.birthDate} onChange={handleChange} /> 
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input">
                        <label className="label" htmlFor="email">E-mail :</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" /> 
                    </div>
                    <div className="input">
                        <label className="password" htmlFor="password">Mot de passe :</label>
                        <input type="password" name="password" placeholder="**************" disabled/> 
                    </div>
                   
                  </div> 
                  <div className="input checkbox-container"> 
                    <input type="checkbox" id="newsletter" className="checkbox-custom" checked={formData.newsletter} onChange={(e) => setFormData({...formData, newsletter: e.target.checked})}/>
                    <label htmlFor="newsletter">Je souhaite être informé(e) des nouveautés de Vaiago par e-mail (pas de spam, c'est promis)</label>
                  </div>
                    <br></br>
                    <button type="submit" disabled={loading}>
                    {loading ? 'Loading...' : 'Sauvegarder'}
                    </button>
                    {error && <p className="error">{error}</p>}      
                    {confirmationMessage && <p className="success">{confirmationMessage}</p>}
              </form>
            </div>
        </div>
    </div>
    );
};  
export default AccountForm;
